import { resolveAuthRepository } from "@di/app";
import { BaseRepository, parseError } from "@features/shared/data";
import { userApiUrl } from "@features/user/data/constants";
import user from "user";
import {
  ChangePasswordData,
  ProfileStoreData,
  UpdateProfileData,
  User,
  UserProfileRepositoryContract,
} from "../../domain";

export class ProfileRepository
  extends BaseRepository
  implements UserProfileRepositoryContract
{
  public async updateProfile(data: UpdateProfileData): Promise<User> {
    try {
      const response = await this.http.patch<UpdateProfileData, User>(
        userApiUrl.updateProfile,
        data,
      );

      user.set("fullName", data.fullName);

      return response;
    } catch (error) {
      const _errorText = parseError(error);
      throw error;
    }
  }

  public async deleteAccount(password: string): Promise<void> {
    try {
      await this.http.patch<{ password: string }, void>(
        userApiUrl.deleteAccount,
        {
          password,
        },
      );

      resolveAuthRepository().logout();
    } catch (error) {
      const _errorText = parseError(error);
      throw error;
    }
  }

  public async getProfile(): Promise<User> {
    const { data } = await this.http.get<{ data: User }>(userApiUrl.getProfile);

    return data.data;
  }

  public async changePassword(data: ChangePasswordData): Promise<void> {
    try {
      // Make the HTTP post request to the server with the register form data
      await this.http.patch<ChangePasswordData, void>(
        userApiUrl.changePassword,
        data,
      );

      // TODO: track change password success event
    } catch (error) {
      // TODO: track change password error
      const _errorText = parseError(error);
      throw error;
    }
  }

  public async getProfileStore(): Promise<ProfileStoreData> {
    try {
      const { data } = await this.http.get<any>(userApiUrl.getProfile);
      return data.data;
    } catch (error) {
      const _errorText = parseError(error);
      throw error;
    }
  }
  public async updateProfileStore(data: ProfileStoreData): Promise<void> {
    try {
      await this.http.patch<ProfileStoreData, User>(
        userApiUrl.updateProfile,
        data,
      );
    } catch (error) {
      const _errorText = parseError(error);
      throw error;
    }
  }
}
