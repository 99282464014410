import { EGY, IRQ, KSA, OMN, UAE } from "@features/country/data";
import { URLS } from "@features/shared/presentation";
import { Store, StoreProvider } from "@features/stores/domain";
import lightFunnelsIcon from "assets/images/stores/light-funnels.svg";

const easyOrdersStore: Store = {
  name: "stores.easyOrders",
  icon: "https://storage.googleapis.com/taager-public-media/learning/easy-orders.svg",
  provider: StoreProvider.EasyOrders,
  redirectRoute: "",
  sortOrder: 1,
  howToConnectVideoUrl:
    "https://www.youtube.com/embed/Sc8xaCReSYI?si=Ot9NTLCkUiupYmIr",
  supportedCountries: [EGY, KSA, IRQ, UAE, OMN],
  courses: [
    {
      title: "stores.courses.easyOrders.title",
      lessons: [
        {
          title: "stores.courses.easyOrders.firstCourse",
          link: "https://taager.com/sa/learning-center/easy-orders1",
        },
        {
          title: "stores.courses.easyOrders.secondCourse",
          link: "https://taager.com/sa/learning-center/easy-orders2",
        },
        {
          title: "stores.courses.easyOrders.thirdCourse",
          link: "https://taager.com/sa/learning-center/easy-orders3",
        },
        {
          title: "stores.courses.easyOrders.fourthCourse",
          link: "https://taager.com/sa/learning-center/easy-orders4",
        },
      ],
    },
  ],
};

const dukanStore: Store = {
  name: "stores.dukan",
  icon: "https://storage.googleapis.com/taager-public-media/learning/dukan.svg",
  provider: StoreProvider.Dukan,
  redirectRoute: "",
  sortOrder: 6,
  supportedCountries: [EGY, UAE],
};

const youCanStore: Store = {
  name: "stores.youCan",
  icon: "https://storage.googleapis.com/taager-public-media/learning/you-can.svg",
  provider: StoreProvider.YouCan,
  redirectRoute: URLS.youcanIntegration,
  sortOrder: 2,
  howToConnectVideoUrl:
    "https://www.youtube.com/embed/lFC15nJs_-A?si=3DEubwXnzXrxzRwJ",
  supportedCountries: [EGY, UAE, KSA, IRQ, OMN],
  shouldDisplayConnectionState: true,
  courses: [
    {
      title: "stores.courses.youcan.title",
      lessons: [
        {
          title: "stores.courses.youcan.firstCourse",
          link: "https://taager.com/sa/learning-center/youcan1",
        },
        {
          title: "stores.courses.youcan.secondCourse",
          link: "https://taager.com/sa/learning-center/youcan2",
        },
      ],
    },
  ],
};

const zidStore: Store = {
  name: "stores.zid",
  icon: "https://storage.googleapis.com/taager-public-media/learning/zid.svg",
  provider: StoreProvider.Zid,
  redirectRoute: URLS.zidIntegration,
  sortOrder: 5,
  supportedCountries: [KSA],
  shouldDisplayConnectionState: true,
  courses: [
    {
      title: "stores.courses.zid.title",
      lessons: [
        {
          title: "stores.courses.zid.firstCourse",
          link: "https://taager.com/sa/learning-center/taager-zid-plugin1",
        },
      ],
    },
  ],
};

const wooCommerceStore: Store = {
  name: "stores.wooCommerce",
  icon: "https://storage.googleapis.com/taager-public-media/learning/woo-commerce.svg",
  provider: StoreProvider.WooCommerce,
  redirectRoute: "",
  sortOrder: 4,
  supportedCountries: [EGY, UAE, KSA, IRQ, OMN],
  shouldDisplayConnectionState: true,
  howToConnectVideoUrl:
    "https://www.youtube.com/embed/ahN6iXS3M-0?si=yQO8Wp_4qCGWmAZr",
  courses: [
    {
      title: "stores.courses.woocommerce.title",
      lessons: [
        {
          title: "stores.courses.woocommerce.firstCourse",
          link: "https://taager.com/sa/learning-center/taager-wordpress-plugin1",
        },
      ],
    },
  ],
};

export const lightFunnelsStore: Store = {
  name: "stores.lightFunnels",
  // icon: "https://storage.googleapis.com/taager-public-media/learning/light-funnels.svg",
  icon: lightFunnelsIcon,
  provider: StoreProvider.LightFunnels,
  shouldDisplayConnectionState: true,
  redirectRoute: URLS.lightFunnelsIntegration,
  supportedCountries: [EGY, UAE, KSA, IRQ, OMN],
  howToConnectVideoUrl: "https://www.youtube.com/embed/AipwmNDvyK4",
  sortOrder: 3,
};

export const storesList: Record<StoreProvider, Store> = {
  [StoreProvider.EasyOrders]: easyOrdersStore,
  [StoreProvider.Dukan]: dukanStore,
  [StoreProvider.YouCan]: youCanStore,
  [StoreProvider.Zid]: zidStore,
  [StoreProvider.WooCommerce]: wooCommerceStore,
  [StoreProvider.LightFunnels]: lightFunnelsStore,
};
